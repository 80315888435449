var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "noticeBarRef", staticClass: "notice-bar" }, [
    _c(
      "div",
      {
        ref: "contentRef",
        staticClass: "notice-bar-content",
        style: _vm.contentStyle
      },
      [_vm._t("default"), _vm.animateFlag ? [_vm._t("default")] : _vm._e()],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }