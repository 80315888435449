var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("van-nav-bar", {
        attrs: { title: "行情", placeholder: "", fixed: "", border: false },
        scopedSlots: _vm._u(
          [
            _vm.userinfo
              ? {
                  key: "left",
                  fn: function() {
                    return [
                      _c("van-icon", {
                        attrs: { name: "bell", color: "#f5f5f5", size: "16" }
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#f5f5f5",
                            "font-size": "12px",
                            "margin-left": "-10px"
                          },
                          on: { click: _vm.quotationShowHandle }
                        },
                        [_vm._v("到价提醒")]
                      )
                    ]
                  },
                  proxy: true
                }
              : null,
            _vm.userinfo
              ? {
                  key: "right",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "righbox" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "message" } } },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/messagebox.png"),
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _c(
        "div",
        { staticClass: "domainInfo" },
        [
          _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "logo" }, [
              _vm.notices.logo
                ? _c("img", { attrs: { src: _vm.notices.logo.url, alt: "" } })
                : _vm._e()
            ]),
            _c("div", { staticClass: "status" }, [
              _c("div", [_vm._v(_vm._s(_vm.timecenter))]),
              _c(
                "div",
                {
                  staticClass: "trade",
                  style: {
                    color: _vm.opentrade == true ? "#32BC77" : "#F94E3C",
                    border:
                      _vm.opentrade == true
                        ? "1px solid #32BC77"
                        : "1px solid #F94E3C"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.opentrade == true ? "开盘中" : "已停盘") +
                      " "
                  )
                ]
              )
            ])
          ]),
          _vm.notices.enable && _vm.noticesText
            ? _c("van-notice-bar", {
                attrs: {
                  text: _vm.noticesText,
                  "left-icon": require("@/assets/images/icon_notices_red_4@2x.png"),
                  background: "#FFFFFF",
                  color: "#333333",
                  speed: "60"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh", "background-color": "#F6F5F9" },
          attrs: { "success-text": "刷新成功" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        [
          _vm.havedata
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "price" },
                  [
                    _c(
                      "ul",
                      [
                        _c("van-sticky", [
                          _c("li", [_vm._v("商品")]),
                          _c("li", [_vm._v("回购")]),
                          _c("li", [_vm._v("销售")]),
                          _c("li", [_vm._v("高/低")])
                        ])
                      ],
                      1
                    ),
                    _vm._l(_vm.arrcenter.spotPrice, function(items, index) {
                      return _c(
                        "ul",
                        { key: index, staticClass: "productlist" },
                        [
                          _c("li", { staticClass: "productName" }, [
                            _vm._v(_vm._s(items.engname))
                          ]),
                          _c(
                            "li",
                            {
                              staticClass: "productPrice",
                              class: _vm.sendmsg
                                ? items.productData["huigouColor"]
                                : "redfont"
                            },
                            [
                              items.productData["hgDisplay"] == "SHOW"
                                ? [
                                    items.productData["huigouColor"] ===
                                    "redfontBack"
                                      ? _c("span", {
                                          staticClass: "triangleUp"
                                        })
                                      : _vm._e(),
                                    items.productData["huigouColor"] ===
                                    "greenfontBack"
                                      ? _c("span", {
                                          staticClass: "triangleDown"
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("changeTwoDecimal_x")(
                                            items.productData["huigou"]
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                : [_vm._v(" - ")]
                            ],
                            2
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "productPrice",
                              class: items.productData["huigouColor"]
                            },
                            [
                              items.productData["xsDisplay"] == "SHOW"
                                ? [
                                    items.productData["huigouColor"] ===
                                    "redfontBack"
                                      ? _c("span", {
                                          staticClass: "triangleUp"
                                        })
                                      : _vm._e(),
                                    items.productData["huigouColor"] ===
                                    "greenfontBack"
                                      ? _c("span", {
                                          staticClass: "triangleDown"
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("changeTwoDecimal_x")(
                                            items.productData["xiaoshou"]
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                : [_vm._v(" - ")]
                            ],
                            2
                          ),
                          _c("li", { staticClass: "lastli" }, [
                            _c(
                              "div",
                              {
                                staticClass: "high",
                                class:
                                  items.productData["huigouColor"] ===
                                    "greenfont" ||
                                  items.productData["huigouColor"] ===
                                    "greenfontBack"
                                    ? "greenfont"
                                    : "redfont"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("changeTwoDecimal_x")(
                                        items.productData["high"]
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "low",
                                class:
                                  items.productData["huigouColor"] ===
                                    "greenfont" ||
                                  items.productData["huigouColor"] ===
                                    "greenfontBack"
                                    ? "greenfont"
                                    : "redfont"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("changeTwoDecimal_x")(
                                        items.productData["low"]
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c("div", { staticClass: "price" }, [
                  _c("ul", { staticClass: "topul" }, [
                    _c("li", [_vm._v("旧料回收")])
                  ]),
                  _c("div", { staticClass: "oldProductlist" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "50%" } },
                      _vm._l(_vm.arrcenter.huangjin, function(items, index) {
                        return index !== 0
                          ? _c(
                              "div",
                              { key: index, staticClass: "oldProductBox" },
                              [
                                _c("div", { staticClass: "oldProductName" }, [
                                  _vm._v(_vm._s(items.productData["name"]))
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "oldProductPrice",
                                    class: _vm.sendmsg
                                      ? items.productData["huigouColor"] ===
                                          "greenfont" ||
                                        items.productData["huigouColor"] ===
                                          "greenfontBack"
                                        ? "greenfont"
                                        : "redfont"
                                      : "redfont"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("changeTwoDecimal_x")(
                                            items.productData["huigou"]
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "50%" } },
                      _vm._l(_vm.arrcenter.baiyin, function(items, index) {
                        return index !== 0
                          ? _c(
                              "div",
                              { key: index, staticClass: "oldProductBox" },
                              [
                                _c("div", { staticClass: "oldProductName" }, [
                                  _vm._v(_vm._s(items.productData["name"]))
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "oldProductPrice",
                                    class: _vm.sendmsg
                                      ? items.productData["huigouColor"] ===
                                          "greenfont" ||
                                        items.productData["huigouColor"] ===
                                          "greenfontBack"
                                        ? "greenfont"
                                        : "redfont"
                                      : "redfont"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("changeTwoDecimal_x")(
                                            items.productData["huigou"]
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "oldProductlist",
                      staticStyle: {
                        "border-top": "1px solid #d8d8d8",
                        "margin-top": "-5px"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "50%" } },
                        _vm._l(_vm.arrcenter.bojin, function(items, index) {
                          return index !== 0
                            ? _c(
                                "div",
                                { key: index, staticClass: "oldProductBox" },
                                [
                                  _c("div", { staticClass: "oldProductName" }, [
                                    _vm._v(_vm._s(items.productData["name"]))
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "oldProductPrice",
                                      class: _vm.sendmsg
                                        ? items.productData["huigouColor"] ===
                                            "greenfont" ||
                                          items.productData["huigouColor"] ===
                                            "greenfontBack"
                                          ? "greenfont"
                                          : "redfont"
                                        : "redfont"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_x")(
                                              items.productData["huigou"]
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%" } },
                        _vm._l(_vm.arrcenter.bajin, function(items, index) {
                          return index !== 0
                            ? _c(
                                "div",
                                { key: index, staticClass: "oldProductBox" },
                                [
                                  _c("div", { staticClass: "oldProductName" }, [
                                    _vm._v(_vm._s(items.productData["name"]))
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "oldProductPrice",
                                      class: _vm.sendmsg
                                        ? items.productData["huigouColor"] ===
                                            "greenfont" ||
                                          items.productData["huigouColor"] ===
                                            "greenfontBack"
                                          ? "greenfont"
                                          : "redfont"
                                        : "redfont"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_x")(
                                              items.productData["huigou"]
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "price" },
                  [
                    _c("ul", { staticClass: "topul" }, [
                      _c("li", [_vm._v("上交所报价")])
                    ]),
                    _vm._l(_vm.arrcenter.shangjinsuo, function(items, index) {
                      return items.productData["name"] !== "黄金99.95"
                        ? _c("ul", { key: index, staticClass: "productlist" }, [
                            _c("li", { staticClass: "productName" }, [
                              _vm._v(
                                _vm._s(
                                  items.productData["name"] == "黄金99.99"
                                    ? "黄金9999"
                                    : items.productData["name"] == "黄金99.95"
                                    ? "黄金9995"
                                    : items.productData["name"]
                                )
                              )
                            ]),
                            _c(
                              "li",
                              {
                                staticClass: "productPrice",
                                class: _vm.sendmsg
                                  ? items.productData["huigouColor"]
                                  : "redfont"
                              },
                              [
                                items.productData["hgDisplay"] == "SHOW"
                                  ? [
                                      items.productData["huigouColor"] ===
                                      "redfontBack"
                                        ? _c("span", {
                                            staticClass: "triangleUp"
                                          })
                                        : _vm._e(),
                                      items.productData["huigouColor"] ===
                                      "greenfontBack"
                                        ? _c("span", {
                                            staticClass: "triangleDown"
                                          })
                                        : _vm._e(),
                                      items.productData["name"] == "白银(T+D)"
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  items.productData["huigou"]
                                                ) +
                                                " "
                                            )
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("changeTwoDecimal_f")(
                                                    items.productData["huigou"]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                    ]
                                  : [_vm._v(" - ")]
                              ],
                              2
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "productPrice",
                                class: items.productData["huigouColor"]
                              },
                              [
                                items.productData["xsDisplay"] == "SHOW"
                                  ? [
                                      items.productData["huigouColor"] ===
                                      "redfontBack"
                                        ? _c("span", {
                                            staticClass: "triangleUp"
                                          })
                                        : _vm._e(),
                                      items.productData["huigouColor"] ===
                                      "greenfontBack"
                                        ? _c("span", {
                                            staticClass: "triangleDown"
                                          })
                                        : _vm._e(),
                                      items.productData["name"] == "白银(T+D)"
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  items.productData["xiaoshou"]
                                                ) +
                                                " "
                                            )
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("changeTwoDecimal_f")(
                                                    items.productData[
                                                      "xiaoshou"
                                                    ]
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                    ]
                                  : [_vm._v(" - ")]
                              ],
                              2
                            ),
                            _c("li", { staticClass: "lastli" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "high",
                                  class:
                                    items.productData["huigouColor"] ===
                                      "greenfont" ||
                                    items.productData["huigouColor"] ===
                                      "greenfontBack"
                                      ? "greenfont"
                                      : "redfont"
                                },
                                [
                                  items.productData["name"] == "白银(T+D)"
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(items.productData["high"]) +
                                            " "
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("changeTwoDecimal_f")(
                                                items.productData["high"]
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "low",
                                  class:
                                    items.productData["huigouColor"] ===
                                      "greenfont" ||
                                    items.productData["huigouColor"] ===
                                      "greenfontBack"
                                      ? "greenfont"
                                      : "redfont"
                                },
                                [
                                  items.productData["name"] == "白银(T+D)"
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(items.productData["low"]) +
                                            " "
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("changeTwoDecimal_f")(
                                                items.productData["low"]
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                ],
                                2
                              )
                            ])
                          ])
                        : _vm._e()
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "price" },
                  [
                    _c("ul", { staticClass: "topul" }, [
                      _c("li", [_vm._v("国际价格")])
                    ]),
                    _vm._l(_vm.arrcenter.guojixianhuo, function(items, index) {
                      return _c(
                        "ul",
                        { key: index, staticClass: "productlist" },
                        [
                          _c("li", { staticClass: "productName" }, [
                            _vm._v(_vm._s(items.productData["name"]))
                          ]),
                          _c(
                            "li",
                            {
                              staticClass: "productPrice",
                              class: _vm.sendmsg
                                ? items.productData["huigouColor"]
                                : "redfont"
                            },
                            [
                              items.productData["hgDisplay"] == "SHOW"
                                ? [
                                    items.productData["huigouColor"] ===
                                    "redfontBack"
                                      ? _c("span", {
                                          staticClass: "triangleUp"
                                        })
                                      : _vm._e(),
                                    items.productData["huigouColor"] ===
                                    "greenfontBack"
                                      ? _c("span", {
                                          staticClass: "triangleDown"
                                        })
                                      : _vm._e(),
                                    items.productData["name"] == "伦敦金" ||
                                    items.productData["name"] == "伦敦钯" ||
                                    items.productData["name"] == "伦敦铂"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_f")(
                                                  items.productData["huigou"]
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      : items.productData["name"] == "伦敦银" ||
                                        items.productData["name"] == "美元指数"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f(
                                                  "changeTwoDecimal_fthree"
                                                )(items.productData["huigou"])
                                              ) +
                                              " "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                items.productData["huigou"]
                                              ) +
                                              " "
                                          )
                                        ]
                                  ]
                                : [_vm._v(" - ")]
                            ],
                            2
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "productPrice",
                              class: _vm.sendmsg
                                ? items.productData["huigouColor"]
                                : "redfont"
                            },
                            [
                              items.productData["xsDisplay"] == "SHOW"
                                ? [
                                    items.productData["huigouColor"] ===
                                    "redfontBack"
                                      ? _c("span", {
                                          staticClass: "triangleUp"
                                        })
                                      : _vm._e(),
                                    items.productData["huigouColor"] ===
                                    "greenfontBack"
                                      ? _c("span", {
                                          staticClass: "triangleDown"
                                        })
                                      : _vm._e(),
                                    items.productData["name"] == "伦敦金" ||
                                    items.productData["name"] == "伦敦钯" ||
                                    items.productData["name"] == "伦敦铂"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("changeTwoDecimal_f")(
                                                  items.productData["xiaoshou"]
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      : items.productData["name"] == "伦敦银" ||
                                        items.productData["name"] == "美元指数"
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f(
                                                  "changeTwoDecimal_fthree"
                                                )(items.productData["xiaoshou"])
                                              ) +
                                              " "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                items.productData["xiaoshou"]
                                              ) +
                                              " "
                                          )
                                        ]
                                  ]
                                : [_vm._v(" - ")]
                            ],
                            2
                          ),
                          _c("li", { staticClass: "lastli" }, [
                            _c(
                              "div",
                              {
                                staticClass: "high",
                                class:
                                  items.productData["huigouColor"] ===
                                    "greenfont" ||
                                  items.productData["huigouColor"] ===
                                    "greenfontBack"
                                    ? "greenfont"
                                    : "redfont"
                              },
                              [
                                items.productData["name"] == "伦敦金" ||
                                items.productData["name"] == "伦敦钯" ||
                                items.productData["name"] == "伦敦铂"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_f")(
                                              items.productData["high"]
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  : items.productData["name"] == "伦敦银" ||
                                    items.productData["name"] == "美元指数"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_fthree")(
                                              items.productData["high"]
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        " " +
                                          _vm._s(items.productData["high"]) +
                                          " "
                                      )
                                    ]
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "low",
                                class:
                                  items.productData["huigouColor"] ===
                                    "greenfont" ||
                                  items.productData["huigouColor"] ===
                                    "greenfontBack"
                                    ? "greenfont"
                                    : "redfont"
                              },
                              [
                                items.productData["name"] == "伦敦金" ||
                                items.productData["name"] == "伦敦钯" ||
                                items.productData["name"] == "伦敦铂"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_f")(
                                              items.productData["low"]
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  : items.productData["name"] == "伦敦银" ||
                                    items.productData["name"] == "美元指数"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("changeTwoDecimal_fthree")(
                                              items.productData["low"]
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        " " +
                                          _vm._s(items.productData["low"]) +
                                          " "
                                      )
                                    ]
                              ],
                              2
                            )
                          ])
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c("div", { staticClass: "remark" }, [
                  _vm.notices.tips
                    ? _c("div", { staticClass: "describe" }, [
                        _vm._v("温馨提示："),
                        _c("span", [_vm._v(_vm._s(_vm.notices.tips))])
                      ])
                    : _vm._e(),
                  _vm.notices.address
                    ? _c("div", { staticClass: "describe" }, [
                        _vm._v("地址："),
                        _c("span", [_vm._v(_vm._s(_vm.notices.address))])
                      ])
                    : _vm._e(),
                  _vm.notices.phone
                    ? _c("div", { staticClass: "describe" }, [
                        _vm._v("业务咨询电话："),
                        _c("span", [_vm._v(_vm._s(_vm.notices.phone))])
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "beian" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://beian.miit.gov.cn/"
                      }
                    },
                    [_vm._v("粤ICP备2022080044号-1")]
                  )
                ])
              ])
            : _c("div", [_c("van-loading", { attrs: { size: "38px" } })], 1)
        ]
      ),
      _c(
        "van-popup",
        {
          staticStyle: { background: "transparent", width: "350px" },
          attrs: { "close-icon": "close" },
          on: { close: _vm.noticeClose },
          model: {
            value: _vm.showNotice,
            callback: function($$v) {
              _vm.showNotice = $$v
            },
            expression: "showNotice"
          }
        },
        [
          _c("img", { attrs: { src: _vm.noticeImg, alt: "" } }),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticStyle: { height: "40px", width: "40px" },
              attrs: {
                src: require("@/assets/images/close_notice.png"),
                alt: ""
              },
              on: { click: _vm.noticeClose }
            })
          ])
        ]
      ),
      _c(
        "van-popup",
        {
          attrs: {
            position: "bottom",
            "close-icon-position": "top-left",
            closeable: "",
            round: ""
          },
          model: {
            value: _vm.quotationShow,
            callback: function($$v) {
              _vm.quotationShow = $$v
            },
            expression: "quotationShow"
          }
        },
        [
          _c("div", { staticClass: "quotationTitle" }, [_vm._v(" 到价提醒 ")]),
          _c("div", { staticClass: "quotationForm" }, [
            _c("div", { staticClass: "flex quotationFormItem" }, [
              _c("div", { staticClass: "titleinfo" }, [_vm._v("订阅商品")]),
              _c(
                "div",
                {
                  staticClass: "flex_between",
                  on: {
                    click: function($event) {
                      _vm.categoryShow = true
                    }
                  }
                },
                [
                  _c("div", { staticClass: "titlevalue" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.quotationForm.key === "bojin9996"
                            ? "铂金"
                            : _vm.quotationForm.key === "bajin9996"
                            ? "钯金"
                            : _vm.quotationForm.key === "baiyin"
                            ? "白银"
                            : "黄金"
                        ) +
                        " "
                    )
                  ]),
                  _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
                ]
              )
            ]),
            _c("div", { staticClass: "flex quotationFormItem" }, [
              _c("div", { staticClass: "titleinfo" }, [_vm._v("当前价格")]),
              _c("div", { staticClass: "titlevalue" }, [
                _vm._v(" " + _vm._s(_vm.quotationForm.nowPrice) + " ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "flex quotationFormItem" },
              [
                _c("div", { staticClass: "titleinfo" }, [_vm._v("订阅方向")]),
                _c(
                  "van-radio-group",
                  {
                    attrs: { direction: "horizontal" },
                    on: { change: _vm.quotationFormTypeChange },
                    model: {
                      value: _vm.quotationForm.type,
                      callback: function($$v) {
                        _vm.$set(_vm.quotationForm, "type", $$v)
                      },
                      expression: "quotationForm.type"
                    }
                  },
                  [
                    _c(
                      "van-radio",
                      {
                        attrs: {
                          name: "HUIGOU",
                          "icon-size": "16",
                          "checked-color": "red"
                        }
                      },
                      [_vm._v("回购")]
                    ),
                    _c(
                      "van-radio",
                      {
                        attrs: {
                          name: "XIAOSHOU",
                          "icon-size": "16",
                          "checked-color": "red"
                        }
                      },
                      [_vm._v("销售")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "flex quotationFormItem" }, [
              _c("div", { staticClass: "titleinfo" }, [_vm._v("订阅价格")]),
              _c("div", { staticClass: "titlevalue flex" }, [
                _c("div", [_vm._v("￥")]),
                _c(
                  "div",
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入提醒价格" },
                      model: {
                        value: _vm.quotationForm.price,
                        callback: function($$v) {
                          _vm.$set(_vm.quotationForm, "price", $$v)
                        },
                        expression: "quotationForm.price"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "quotationDescribe" }, [
            _vm._v(
              " " +
                _vm._s(_vm.quotationForm.type === "HUIGOU" ? "高于" : "低于") +
                "设置报价你将收到微信通知 "
            )
          ]),
          _c(
            "el-button",
            {
              staticClass: "quotationBtn",
              attrs: { disabled: _vm.quotationForm.price ? false : true },
              on: { click: _vm.quotationCommit }
            },
            [_vm._v("设置提醒")]
          )
        ],
        1
      ),
      _c("van-action-sheet", {
        attrs: {
          actions: _vm.category,
          "cancel-text": "取消",
          "close-on-click-action": ""
        },
        on: { select: _vm.confirmCategory },
        model: {
          value: _vm.categoryShow,
          callback: function($$v) {
            _vm.categoryShow = $$v
          },
          expression: "categoryShow"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }